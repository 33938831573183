export const joneData = [
  {
    module: 'Open Positions',
    linkColor: '#0c6e9f',
    mainColor: '#01182480',
    shade: '#01182480',
  //   background: "radial-gradient(#704343,black)",
    background: "linear-gradient(179deg, #6e3b05,#ec7600, #ab5702 100%)",
    description: "We have a standing job invitation – we keep looking for passionate talents",
  //   bg: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708174446/Templates/Aurora_Well_Wallpaper_in_Black_to_Dark_Blue_Gradient_hdg3se.jpg',
    thumbnail:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707222161/Group_40453_ad2vrp.png',
    video:
      'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708534248/web%203.mp4',
    highlight:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1700342634/Manuals/library_bfejlb.jpg',
    presentation: [
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41635_yaaffe.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41688_x7kst9.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305643/web%20images/library/Frame_41626_udnecv.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41638_ukwzd3.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41578_zhoj1u.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41579_k842gp.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305643/web%20images/library/Frame_41580_tyfsgf.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41818_hrgwvk.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41629_frhl2d.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305643/web%20images/library/Frame_41628_b2mpaz.jpg',
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305643/web%20images/library/Frame_41614_hptfop.jpg',
    ],
    introContent: {
      subtitle: 'Organize your content',
      title: 'Organize, share and present',
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor lorem arcu, aliquam facilisis augue malesuada a. Phasellus at nisl dignissim sapien laoreet lobortis ac eu turpis."
    },
    results: '/library/results'
  },
  ]
  
export const modulesData = [
    {
      module: 'STARTUPS',
      linkColor: '#0c6e9f',
      mainColor: '#01182480',
      shade: '#01182480',
      background: "radial-gradient(#434a70,black)",
      description: "Raising capital, recruiting, establishing sales and marketing? Try Library, Studio and Magazine to boost the way you can communicate. Stand out and show your edge.",
    //   bg: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708174446/Templates/Aurora_Well_Wallpaper_in_Black_to_Dark_Blue_Gradient_hdg3se.jpg',
      thumbnail:
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707222161/Group_40453_ad2vrp.png',
      video:
        'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708101500/lorita%20start%20ups.mp4',
      highlight:
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1700342634/Manuals/library_bfejlb.jpg',
      presentation: [
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41635_yaaffe.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41688_x7kst9.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305643/web%20images/library/Frame_41626_udnecv.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41638_ukwzd3.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41578_zhoj1u.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41579_k842gp.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305643/web%20images/library/Frame_41580_tyfsgf.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41818_hrgwvk.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305642/web%20images/library/Frame_41629_frhl2d.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305643/web%20images/library/Frame_41628_b2mpaz.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704305643/web%20images/library/Frame_41614_hptfop.jpg',
      ],
      introContent: {
        subtitle: 'Organize your content',
        title: 'Organize, share and present',
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor lorem arcu, aliquam facilisis augue malesuada a. Phasellus at nisl dignissim sapien laoreet lobortis ac eu turpis."
      },
      results: '/library/results'
    },
    {
      module: 'FREELANCING',
      linkColor: '#098d70',
      shade:"#01182480",
      background: "radial-gradient(#434a70,black)",
      mainColor: '#01182480',
      bg: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708168339/Templates/Batman_Catwoman_home_qw7kwt.jpg',
      thumbnail:
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707222242/Group_40460_v54hyu.png',
      video:
        'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708101985/freelancing.mp4',
      highlight:
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1700342634/Manuals/WiZR_X_-_Homepage_kbm8zl.jpg',
        description: "Want to strengthen how you sell and deliver your services? Our personal subscription can be used to elevate how you present and connect with new and current clients.",
      presentation: [
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306166/web%20images/wizrx/Frame_41636_hygr47.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306167/web%20images/wizrx/Frame_41689_lq3g5o.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306165/web%20images/wizrx/Frame_41624_bbc0ya.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306165/web%20images/wizrx/Frame_41600_emok1a.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306167/web%20images/wizrx/Frame_41596_gvfijc.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306164/web%20images/wizrx/Frame_41597_bbhcvy.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306164/web%20images/wizrx/Frame_41599_cmwrxw.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306166/web%20images/wizrx/Frame_41644_huvc75.jpg',
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306163/web%20images/wizrx/Frame_41645_op82db.jpg',
      ],
      introContent: {
        subtitle: 'Connect & Collaborate',
        title: 'Seamless connections ',
        description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor lorem arcu, aliquam facilisis augue malesuada a. Phasellus at nisl dignissim sapien laoreet lobortis ac eu turpis.",
      },
      results: '/wizr-x/results'
    },
    {
        module: 'TRAINING',
        linkColor: '#098d70',
        shade:"#01182480",
        background: "radial-gradient(#434a70,black)",
        mainColor: '#01182480',
        bg: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708168339/Templates/Batman_Catwoman_home_qw7kwt.jpg',
        thumbnail:
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707222242/Group_40460_v54hyu.png',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708101636/lorita%20training%20education.mp4',
        highlight:
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1700342634/Manuals/WiZR_X_-_Homepage_kbm8zl.jpg',
          description: "Want to stand out with your courses, training and programs? It is not only the material but also how material gets presented, made shareable and engage, try WiZR Up.",
        presentation: [
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306166/web%20images/wizrx/Frame_41636_hygr47.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306167/web%20images/wizrx/Frame_41689_lq3g5o.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306165/web%20images/wizrx/Frame_41624_bbc0ya.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306165/web%20images/wizrx/Frame_41600_emok1a.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306167/web%20images/wizrx/Frame_41596_gvfijc.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306164/web%20images/wizrx/Frame_41597_bbhcvy.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306164/web%20images/wizrx/Frame_41599_cmwrxw.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306166/web%20images/wizrx/Frame_41644_huvc75.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306163/web%20images/wizrx/Frame_41645_op82db.jpg',
        ],
        introContent: {
          subtitle: 'Connect & Collaborate',
          title: 'Seamless connections ',
          description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor lorem arcu, aliquam facilisis augue malesuada a. Phasellus at nisl dignissim sapien laoreet lobortis ac eu turpis.",
        },
        results: '/wizr-x/results'
      },
      {
        module: 'PR & MEDIA',
        linkColor: '#098d70',
        shade:"#01182480",
        background: "radial-gradient(#434a70,black)",
        mainColor: '#01182480',
        bg: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708168339/Templates/Batman_Catwoman_home_qw7kwt.jpg',
        thumbnail:
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707222242/Group_40460_v54hyu.png',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708102184/pr%20and%20media.mp4',
        highlight:
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1700342634/Manuals/WiZR_X_-_Homepage_kbm8zl.jpg',
          description: "You focus on design and innovations, how do you find new ways to stand out? Show portfolio, creative talents, projects and best practices beyond what is possible on the web.",
        presentation: [
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306166/web%20images/wizrx/Frame_41636_hygr47.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306167/web%20images/wizrx/Frame_41689_lq3g5o.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306165/web%20images/wizrx/Frame_41624_bbc0ya.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306165/web%20images/wizrx/Frame_41600_emok1a.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306167/web%20images/wizrx/Frame_41596_gvfijc.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306164/web%20images/wizrx/Frame_41597_bbhcvy.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306164/web%20images/wizrx/Frame_41599_cmwrxw.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306166/web%20images/wizrx/Frame_41644_huvc75.jpg',
          'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704306163/web%20images/wizrx/Frame_41645_op82db.jpg',
        ],
        introContent: {
          subtitle: 'Connect & Collaborate',
          title: 'Seamless connections ',
          description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor lorem arcu, aliquam facilisis augue malesuada a. Phasellus at nisl dignissim sapien laoreet lobortis ac eu turpis.",
        },
        results: '/wizr-x/results'
      },
  ]
  

  